// Here you can add other styles
text-left {
	text-align: left;
}
  
text-right {
	text-align: right;
}

.scrollable {
    height: 250px; /* or any value */
    overflow-y: auto;
}

.image-large-view-popover {
	--cui-popover-max-width: 700px;
	--cui-popover-border-color: var(--cui-primary);
	--cui-popover-header-bg: var(--cui-primary);
	--cui-popover-header-color: var(--cui-white);
	--cui-popover-body-padding-x: 1rem;
	--cui-popover-body-padding-y: .5rem;
}